import ApiHttp from '~/api/global/http/ApiHttp';
import type { IUser } from '~/store/user/user.types';

export default class UserHttp extends ApiHttp {
  private readonly userApi: string;
  private readonly userOffers: string;
  private readonly userSessions: string;

  constructor() {
    super();
    this.userApi = '/v1/users/';
    this.userOffers = '/v1/offers/user/';
    this.userSessions = '/v1/users/sessions';
  }

  public async getUser(userId: number) {
    const { data } = await useAsyncData(() => this.$authApi.get(this.userApi + userId));
    return data;
  }

  public async getUserOffers(userId: number) {
    const data = await this.$authApi.get(this.userOffers + userId);
    return data;
  }

  public postUserSession(url: string): Promise<void> {
    return this.$api.post(this.userSessions, {
      body: {
        sessionUrl: url,
      },
    });
  }

  public getMe() {
    return this.$authApi.get<{ data: IUser }>(this.userApi + 'me');
  }
}
