import type { TOAuthKey } from '~/features/general-auth/components/ButtonsGroup/ButtonsGroup.types';

interface IAppsConfigAuth {
  isCheckboxesSelectedByDefault: boolean;
  variants: TOAuthKey[];
}

export enum EHeaderMenuItems {
  BATTLES = 'battles',
  BONUS_PAGE = 'bonusPage',
  CONTRACT = 'contract',
  FREE_CASES = 'freeCases',
  LEADERBOARD = 'leaderboard',
  SECRETSHOP = 'secretShop',
  TECHIES = 'techies',
  UPGRADES = 'upgrades',
}

export enum EStatisticItemVariants {
  ARCANES = 'arcanes',
  BATTLES = 'battles',
  ONLINE = 'online',
  OPENED = 'opened',
  UPGRADES = 'upgrades',
  USERS = 'users',
}

export interface IAppsConfigSeo {
  favicon: string;
  title: string;
  description: string;
}

export interface IAppsConfigTechWork {
  logo: string;
  logoClass: string;
}

export interface IAppsConfigFaq {
  type3: {
    categoryId: number;
    questionId: number;
  };
}

export interface IAppsConfigFooter {
  copyright: string;
  logo: string;
  hidePaymentMethods: boolean;
  hideSocials: boolean;
}

export interface IAppsConfigStatistics {
  hideElements: EStatisticItemVariants[];
}

export interface IAppsConfigRedirects {
  newCases: {
    name: string;
    path: string;
  };
}

export interface IAppsConfigLegalData {
  beneficiary: string;
  registrationNumber: number;
  address: string;
}

export interface IAppsConfig {
  auth: IAppsConfigAuth;
  defaultCurrency: 'RUB' | 'USD';
  faq: IAppsConfigFaq;
  headerLogo: string;
  legalData: IAppsConfigLegalData;
  isTechiesDisabled: boolean;
  hideFreeCasesBanner: boolean;
  hideSecretShop: boolean;
  hideHeaderMenuItems: EHeaderMenuItems[];
  footer: IAppsConfigFooter;
  hideProjectSwitcher: boolean;
  supportKey: string;
  seo: IAppsConfigSeo;
  statistics: IAppsConfigStatistics;
  techWork: IAppsConfigTechWork;
  redirects: IAppsConfigRedirects;
  userAgreementVariant: 'dota' | 'dota-global';
}
