export enum ETabsPresets {
  ARCANA_RAFFLE = 'ArcanaRaffle',
  FREE_CASES = 'FreeCases',
  NEW_CASES = 'NewCases',
  QUIZ = 'Quiz',
  RAFFLE_RESULT = 'RaffleResult',
  TASTY_BATTLE_PASS = 'TastyBattlePass',
  TECHIES = 'Techies',
  TOP = 'Top',
}
export interface IHeaderTabPreset {
  coinIcon?: boolean; // Иконка коина
  customMobileWidth?: number; // Кастомная ширина плашки на мобилке
  customWidth?: number; // Кастомная ширина плашки
  external?: boolean; // Внешняя ссылка
  isDailyCases?: boolean;
  labelText?: string;
  routePath?: string;
  text: string;
  to: string;
}

export interface IHeaderTabProps {
  coinIconSrc: string;
  data: IHeaderTabPreset;
  quantity?: number; // Плашка с количеством
  text?: string; // Плашка с текстом
  time?: number | string; // Плашка с таймером
}
