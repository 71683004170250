export const MAINPAGE_ROUTES = {
  MAINPAGE: {
    name: 'main-page',
    path: '/',
  },
  NEW_CASES: {
    name: 'new-cases',
    path: '/new-cases',
  },
  RAFFLE: {
    name: 'raffle',
    path: '/raffle',
  },
  RAFFLE_RESULT: {
    name: 'raffle-result',
    path: '/raffle-result',
  },
  TOP: {
    name: 'top',
    path: '/top',
  },
};
