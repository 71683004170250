import QuickSaleHttp from './QuickSaleHttp';
import type { IResponseInventoryDTO } from './QuickSaleService.types';

export default class QuickSaleService {
  private readonly quickSaleHttp: QuickSaleHttp;

  constructor() {
    this.quickSaleHttp = new QuickSaleHttp();
  }

  public async getInventory(page: number, perPage: number): Promise<IResponseInventoryDTO> {
    const response = await this.quickSaleHttp.getInventory<IResponseInventoryDTO>(page, perPage);

    if (!response) throw new Error('Error');

    return response;
  }

  public async sellManyInventory(ids: number[]): Promise<void> {
    await this.quickSaleHttp.sellManyInventory(ids);
  }
}
