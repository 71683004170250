import type { ComposerTranslation } from 'vue-i18n';
import type { TValuesTimer } from '~/components/SharedTimer/SharedTimer.types';

export type TWordsForEntityDate = [string, string, string];
export type TCalculatedFunction = (val: number) => number;
export type TUnionDate = 'days' | 'minutes' | 'seconds';

export const getWordEnding = (count: number, words: TWordsForEntityDate): string => {
  const value = Math.abs(count) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  if (!count && !words) return '';

  return words[2];
};

export const getTitle = (entity: number, description: TWordsForEntityDate): string => {
  const length = entity || 0;
  if (!entity && !description) return '';

  return `${getWordEnding(length, description)}`;
};

export const getDateTitle = (value = 0, entityDate: TValuesTimer, t: ComposerTranslation): string | undefined => {
  const variants = ['days', 'hours', 'minutes', 'seconds'];

  if (!entityDate || !variants.includes(entityDate)) throw new Error(`Обработайте значение ${entityDate}`);

  return t(`timer.${entityDate}`, value);
};

export const calculatedTime = (): {
  daysCalculated: TCalculatedFunction;
  minutesCalculated: TCalculatedFunction;
  secondsCalculated: TCalculatedFunction;
} => {
  return {
    daysCalculated: (val: number) => val / 86400,
    minutesCalculated: (val: number) => (val % 3600) / 60,
    secondsCalculated: (val: number) => val % 60,
  };
};

const { daysCalculated, minutesCalculated, secondsCalculated } = calculatedTime();

export const formatList = {
  days: (val: number) => daysCalculated(val),
  hours: (val: number) => (val % 86400) / 3600,
  minutes: (val: number) => minutesCalculated(val),
  seconds: (val: number) => secondsCalculated(val),
};

export function isTimeDifferenceMoreThenDay(date: string | undefined) {
  if (date) {
    const currentDate = new Date();
    const targetDate = new Date(date);
    const timeDifference = Math.abs(currentDate.getTime() - targetDate.getTime());
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    return timeDifference > oneDayInMilliseconds;
  }
}

export function timestampToDate(timestamp: number) {
  const date = new Date(timestamp * 1000);
  // изменил 19 на 22
  return date.toISOString().slice(0, 22).replace('T', ' ');
}

// Добавить к текущему timestamp определённое время, день, минуты, секунды
export const addTime = () => {
  const calculateDateAndTime = {
    days: 86400000,
    minutes: 60000,
    seconds: 1000,
  };

  function calculateUnknownDate(currentItem: number, value: number, type: keyof typeof calculateDateAndTime): number {
    return currentItem + value * calculateDateAndTime[type as keyof typeof calculateDateAndTime];
  }

  return {
    daysCalculated: (days: number, currentItem: number): number => calculateUnknownDate(currentItem, days, 'days'),
    minutesCalculated: (minutes: number, currentItem: number): number =>
      calculateUnknownDate(currentItem, minutes, 'minutes'),
    secondsCalculated: (seconds: number, currentItem: number): number =>
      calculateUnknownDate(currentItem, seconds, 'seconds'),
  };
};

export const convertSecondsFromUTC = (seconds?: number) => {
  if (!seconds) return new Date().toUTCString();
  return new Date(seconds * 1000).toUTCString();
};
