// Ключи из query/params
import type { IUrlOptions, TValidators } from '~/features/bk/types/integrator.types';
import { Currency } from '~/constants/currency.constants';
import { languageIsoDataForBk } from '~/repository/extensions/i18n/data';

// Обязательные параметры в квери url
const requiredUrlPropertiesKey: (keyof IUrlOptions)[] = ['auth-token', 'currencyIso', 'isDemo', 'languageIso'];

// валидатор для ключей в query
const validatorsQuery: TValidators = {
  currencyIso: {
    handler: (val) => {
      return !!Currency[val as keyof typeof Currency];
    },
  },
  isDemo: {
    handler: (val) => {
      return val === 'true' || val === 'false';
    },
    transform: <T>(val: T) => val === 'true',
  },
  languageIso: {
    handler: () => true,
    transform: <T>(val: T) => languageIsoDataForBk[val as keyof typeof languageIsoDataForBk],
  },
};

// Обязательные параметры внутри пути урл
const requiredUrlPropertiesPathKey: (keyof IUrlOptions)[] = ['integratorUuid', 'caseName'];

export { requiredUrlPropertiesPathKey, requiredUrlPropertiesKey, validatorsQuery };
