import type { TPossibleNull } from '~/types/Shared.types';
import ApiQueryHttp from '~/api/global/http/ApiQueryHttp';

type TQueryParams = Record<string, string | number>;

export default class PaymentHttp extends ApiQueryHttp {
  protected readonly inventoryApi: string;
  protected readonly apiMethodGet: string;
  protected readonly apiMethodSell: string;

  constructor() {
    super();
    this.inventoryApi = '/v1/inventory';
    this.apiMethodGet = '/get';
    this.apiMethodSell = '/sell';
  }

  /* GET */

  public async getInventory<IResponseInventoryDTO>(
    page: number,
    perPage: number,
  ): Promise<TPossibleNull<IResponseInventoryDTO>> {
    const queries: TQueryParams = { page, perPage };
    const queryString = this.query.serialise(queries);

    const { data } = await this.$authApi.get<{ data: IResponseInventoryDTO }>(
      this.inventoryApi + this.apiMethodGet + queryString,
    );

    return (data as IResponseInventoryDTO) || null;
  }

  /* SELL */

  public async sellManyInventory(ids: number[]): Promise<void> {
    await this.$authApi.post(this.inventoryApi + this.apiMethodSell + '/many', {
      body: {
        ids,
      },
      mapBeforeSend: false,
    });
  }
}
