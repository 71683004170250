export enum AlertCodes {
  ALL_ITEMS_IN_SALE_QUEUE = 'all_items_in_sale_queue',
  DATA_UPDATED = 'data_updated',
  ENTER_SUCCESS = 'enter_success',
  ERROR = 'error',
  ERROR_BATTLE_AUTHORIZE = 'error_battle_authorize',
  ERROR_EVENT_SETTINGS = 'error_event_settings',
  ERROR_GENERATION = 'error_generation',
  ERROR_MAX_ROUNDS = 'error_max_rounds',
  ERROR_NOT_ENOUGH_FUNDS = 'error_not_enough_funds',
  ERROR_NOT_ENOUGH_FUNDS_FOR_BATTLE = 'error_not_enough_funds_for_battle',
  ERROR_OCCURRED = 'error_occurred',
  ERROR_SKIN_TRANSFER = 'error_skin_transfer',
  ERROR_TOO_MANY_ROUNDS = 'error_too_many_rounds',
  ERROR_WITHDRAWAL = 'error_item_withdrawal',
  ITEM_IN_SALE_QUEUE = 'item_in_sale_queue',
  ITEM_IN_WITHDRAWAL_QUEUE = 'item_in_withdrawal_queue',
  ITEM_IN_WITHDRAWAL_STEAM_QUEUE = 'item_in_withdrawal_steam_queue',
  ITEM_RECEIVED = 'item_received',
  ITEM_TRANSFER_CANCELED = 'item_transfer_canceled',
  JOINING_BATTLE = 'joining_battle',
  LEVEL_GENERATION = 'level_generation',
  PREPARING_TO_DISPATCH = 'preparing_to_dispatch',
  REGISTRATION_SUCCESS = 'registration_success',
  STATUS_NOT_FOUND = 'status_not_found',
}

export const AlertMessages = {
  [AlertCodes.ALL_ITEMS_IN_SALE_QUEUE]: 'Все скины поставлены в очередь для продажи',
  [AlertCodes.DATA_UPDATED]: 'Данные успешно обновлены',
  [AlertCodes.ITEM_IN_SALE_QUEUE]: 'Предмет поставлен в очередь на продажу',
  [AlertCodes.ITEM_RECEIVED]: 'Предмет успешно получен',
  [AlertCodes.ITEM_TRANSFER_CANCELED]: 'Передача предмета успешно отменена',
  [AlertCodes.ITEM_IN_WITHDRAWAL_QUEUE]: 'Предмет поставлен в очередь на вывод',
  [AlertCodes.ERROR_WITHDRAWAL]: 'Произошла ошибка вывода, повторите позже',
  [AlertCodes.STATUS_NOT_FOUND]: 'Статус не найден',
  [AlertCodes.ITEM_IN_WITHDRAWAL_STEAM_QUEUE]: 'Предмет успешно поставлен в очередь steam на вывод',
  [AlertCodes.ERROR]: 'Ошибка',
  [AlertCodes.ERROR_SKIN_TRANSFER]: 'Ошибка продажи скинов',
  [AlertCodes.ERROR_BATTLE_AUTHORIZE]: 'Чтобы создать Батл, необходимо авторизоваться',
  [AlertCodes.REGISTRATION_SUCCESS]: 'Регистрация прошла успешно',
  [AlertCodes.ERROR_EVENT_SETTINGS]: 'Не удалось получить настройки события',
  [AlertCodes.ERROR_OCCURRED]: 'Произошла ошибка',
  [AlertCodes.ERROR_GENERATION]: 'Во время генерации произошла ошибка',
  [AlertCodes.LEVEL_GENERATION]: 'Генерация уровня',
  [AlertCodes.ENTER_SUCCESS]: 'Вход выполнен успешно',
  [AlertCodes.ERROR_MAX_ROUNDS]: 'Максимальное количество раундов: 50',
  [AlertCodes.ERROR_TOO_MANY_ROUNDS]: 'Ошибка: слишком много раундов',
  [AlertCodes.ERROR_NOT_ENOUGH_FUNDS_FOR_BATTLE]: 'У вас недостаточно средств, чтобы участвовать в батле',
  [AlertCodes.ERROR_NOT_ENOUGH_FUNDS]: 'Недостаточно денег',
  [AlertCodes.JOINING_BATTLE]: 'Присоединение к батлу',
  [AlertCodes.PREPARING_TO_DISPATCH]: 'Готовим к отправке!',
};
