import type { IUrlOptions } from '~/features/bk/types/integrator.types';
import type { languageIsoData } from '~/repository/extensions/i18n/data';
import { useQuery } from '~/composables/useQuery';

export function useIntegratorApi(urlOptions: Ref<IUrlOptions>, clientId?: Ref<string>) {
  const defaultUrl = computed(() => `/integrator/${urlOptions.value.integratorUuid}/`);
  // Подключить к указанному url integratorUuid & clientId // api/integrator/1232131/case/open?client_id=123

  const { query } = useQuery();

  // eslint-disable-next-line no-console
  if (!clientId) console.warn('clientId is not defined');
  const createAuthUrl = (url: string): string =>
    `${defaultUrl.value}${url}?client_id=${clientId?.value}&language_iso=${computedLanguageForCase.value || 'ru'}`;

  const validLanguageIso = computed<keyof typeof languageIsoData | undefined>(
    () => urlOptions.value.languageIso && (urlOptions.value.languageIso.split('-')[0] as keyof typeof languageIsoData),
  );

  const computedLanguageForCase = computed(() =>
    !query!.languageIso || query!.languageIso.length !== 2 ? 'zz' : (query!.languageIso as string),
  );

  // языка с кодом zz нет, поэтому мы на бэк отправим zz,
  // если в принципе не передали язык в url и с бэка получим дефолтный язык
  const computedLanguage = computed(() => validLanguageIso.value || computedLanguageForCase.value);

  return {
    createAuthUrl,
    defaultUrl,
    computedLanguage,
    computedLanguageForCase,
  };
}
