export default {
  battles: () => import("/app/layers/dota/layouts/battles.vue").then(m => m.default || m),
  "card-form": () => import("/app/layers/dota/layouts/card-form.vue").then(m => m.default || m),
  contacts: () => import("/app/layers/dota/layouts/contacts.vue").then(m => m.default || m),
  cookies: () => import("/app/layers/dota/layouts/cookies.vue").then(m => m.default || m),
  default: () => import("/app/layers/dota/layouts/default.vue").then(m => m.default || m),
  "event-condition": () => import("/app/layers/dota/layouts/eventCondition.vue").then(m => m.default || m),
  faq: () => import("/app/layers/dota/layouts/faq.vue").then(m => m.default || m),
  hamster: () => import("/app/layers/dota/layouts/hamster.vue").then(m => m.default || m),
  "main-page": () => import("/app/layers/dota/layouts/main-page.vue").then(m => m.default || m),
  payment: () => import("/app/layers/dota/layouts/payment.vue").then(m => m.default || m),
  techies: () => import("/app/layers/dota/layouts/techies.vue").then(m => m.default || m),
  "user-agreement": () => import("/app/layers/dota/layouts/userAgreement.vue").then(m => m.default || m)
}