import InventoryHttp from './inventoryHttp';
import ApiHttp from '~/api/global/http/ApiHttp';

export default class InventoryService extends ApiHttp {
  private readonly inventoryHttp: InventoryHttp;
  constructor() {
    super();
    this.inventoryHttp = new InventoryHttp();
  }

  public sellItem = () => {
    return this.inventoryHttp.sellItem();
  };

  public sellAllItems = () => {
    return this.inventoryHttp.sellAllItems();
  };

  public tradeItem = () => {
    this.inventoryHttp.tradeItem();
  };

  public takeItem = () => {
    this.inventoryHttp.takeItem();
  };

  public sellDrop(id: string) {
    return this.inventoryHttp.sellDrop(id);
  }
}
