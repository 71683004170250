<template>
  <div class="checkboxes">
    <SharedCheckbox
      v-model="agreementChecked"
      class="checkboxes__item"
      :active-background-color="CHECKBOX_COLOR"
      :background-color="CHECKBOX_COLOR"
    >
      <template #appendText>
        <span class="checkboxes__item-content">
          <I18nT keypath="generalAuth.checkboxes.agreement.firstLine" tag="p">
            <template #link>
              <NuxtLinkLocale :to="ROUTING.USERAGREEMENT.MAIN">
                {{ $t('generalAuth.checkboxes.agreement.link') }}
              </NuxtLinkLocale>
            </template>
          </I18nT>
          <p>
            <NuxtLinkLocale :to="ROUTING.EVENTCONDITION.MAIN">
              {{ $t('generalAuth.checkboxes.agreement.secondLine') }}
            </NuxtLinkLocale>
          </p>
        </span>
      </template>
    </SharedCheckbox>
    <SharedCheckbox
      v-model="ageChecked"
      class="checkboxes__item"
      :active-background-color="CHECKBOX_COLOR"
      :background-color="CHECKBOX_COLOR"
    >
      <template #appendText>
        <span class="checkboxes__item-content">{{ $t('generalAuth.checkboxes.age') }}</span>
      </template>
    </SharedCheckbox>
  </div>
</template>

<script lang="ts" setup>
import type { ICheckboxesProps, ICheckboxesEmits } from './Checkboxes.types';

const CHECKBOX_COLOR = 'var(--main-color-100)';

const props = defineProps<ICheckboxesProps>();
const { isSelectedByDefault } = toRefs(props);

const emit = defineEmits<ICheckboxesEmits>();

const agreementChecked = ref(false);
const ageChecked = ref(false);

watch(
  isSelectedByDefault,
  (value) => {
    agreementChecked.value = value;
    ageChecked.value = value;
  },
  { immediate: true },
);

watch(
  [ageChecked, agreementChecked],
  ([ageValue, agreementValue]) => {
    emit('isAllCheckboxesChecked', ageValue && agreementValue);
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped src="./Checkboxes.scss" />
