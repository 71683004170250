import { skipHydrate } from 'pinia';
import type { IBattlesCaseEntity, IBattlesCasesSectionEntity } from '~/repository/modules/battles';
import { useBattlesDataStateStore } from '~/features/battles/store/dataState.store';
import { EBattlesStoreNames } from '~/features/battles/constants/general';

/* Хранилище всех секций и их кейсов, используемых в фиче батлов */
export const useBattlesSectionsStore = defineStore(EBattlesStoreNames.SECTIONS, () => {
  /* Импорт апи модуля */
  const {
    $api: { battles: BattlesApiService },
  } = useNuxtApp();
  const battlesDataStateStore = useBattlesDataStateStore();

  /* -- Const -- */
  /* Все кейсы, участвующие в батлах, собранные в плоскую коллекцию */
  const sectionsCases = ref<Map<number, IBattlesCaseEntity>>(new Map());

  /* Все секции кейсов, которые могут участвовать в фиче батлов */
  const sections = ref<IBattlesCasesSectionEntity[]>([]);

  /* Максимальное доступное количество раундов для создания батла */
  const maxRoundsAvailable = ref<number>(0);

  /* -- Actions -- */

  /**
   * Получение секций по API
   */
  /* Получение всех секций, участвующих в батлах, и формирование плоской коллекции кейсов */
  const fetchSections = async () => {
    try {
      const { sections: responseSections, maxRounds: responseMaxRounds } = await BattlesApiService.getCaseSections();
      if (!responseSections) return;

      sections.value = [];
      if (responseMaxRounds) maxRoundsAvailable.value = responseMaxRounds;

      for (let i = 0; i < responseSections.length; i++) {
        const section = responseSections[i];

        section.cases
          .sort((case1, case2) => case1.price - case2.price)
          .forEach((singleCase) => sectionsCases.value.set(singleCase.id, singleCase));

        sections.value.push(section);
      }
    } finally {
      battlesDataStateStore.changeDataState('areSectionsLoaded', true);
    }
  };

  return {
    fetchSections,
    maxRoundsAvailable,
    sections: skipHydrate(sections),
    sectionsCases: skipHydrate(sectionsCases),
  };
});
