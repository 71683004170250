import ApiHttp from '~/api/global/http/ApiHttp';
import type {
  ICreateAuthUrlArg,
  IGetUserArgs,
  IReplaceItemArgs,
  ISellItemArgs,
  ISendItemArgs,
} from '~/repository/modules/bk/bk.types';

export default class BkSecondHttp extends ApiHttp {
  public async getUser<T>({ bkPrefix, currency, language, authToken }: IGetUserArgs) {
    const { data } = await this.$bkSecondApi.get<{ data: T }>(
      bkPrefix + `user?language_iso=${language}&currency_iso=${currency}`,
      {
        headers: {
          'Bk-Auth-Token': authToken,
        },
      },
    );
    return data;
  }

  public setSteamLogin<T>(steamLogin: string, clientId: string, { bkPrefix }: Partial<IGetUserArgs>) {
    return this.$bkSecondApi.post<T>(bkPrefix + `user/steam/set/login?client_id=${clientId}`, {
      body: {
        steamLogin,
      },
      headers: {
        Cookie: 'external_integrator=1',
      },
      mapBeforeSend: false,
    });
  }

  public setSteamTradeLink<T>(steamTradeUrl: string, clientId: string, { bkPrefix }: Partial<IGetUserArgs>) {
    return this.$bkSecondApi.post<T>(bkPrefix + `user/steam/set/trade/link?client_id=${clientId}`, {
      body: {
        link: steamTradeUrl,
      },
      headers: {
        Cookie: 'external_integrator=1',
      },
    });
  }

  // inventory
  public async getNewInventory<T>({ createAuthUrl }: ICreateAuthUrlArg, page: number) {
    const { data } = await this.$bkSecondApi.post<{ data: T }>(createAuthUrl(`inventory/new/items`), {
      body: {
        page,
      },
    });
    return data;
  }

  public async sendItem<T>({ id, createAuthUrl }: ISendItemArgs) {
    return await this.$bkSecondApi.post<T>(createAuthUrl(`send/item`), {
      body: {
        id,
        method: 'senditem',
      },
    });
  }

  public async getOldInventory<T>({ createAuthUrl }: ICreateAuthUrlArg, page: number) {
    const { data } = await this.$bkSecondApi.post<{ data: T }>(createAuthUrl(`inventory/old/items`), {
      body: {
        page,
      },
    });
    return data;
  }

  // Замена предмета
  public async replaceItem<T>({ createAuthUrl, replaceId, itemId }: IReplaceItemArgs) {
    return await this.$bkSecondApi.post<T>(createAuthUrl(`replace/item`), {
      body: {
        itemId,
        replaceId,
      },
    });
  }

  public async sellItemInventory<T>({ id, createAuthUrl }: ISellItemArgs) {
    return await this.$bkSecondApi.put<T>(createAuthUrl(`inventory/sell/${id}`));
  }

  public async sellAllItemInventory<T>({ createAuthUrl }: ICreateAuthUrlArg) {
    return await this.$bkSecondApi.post<T>(createAuthUrl(`inventory/sell/all`));
  }
}
