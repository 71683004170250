import { EHeaderMenuItems, EStatisticItemVariants, type IAppsConfig } from './index.types';

export default <IAppsConfig>{
  auth: {
    isCheckboxesSelectedByDefault: false,
    variants: ['steam', 'google'],
  },
  defaultCurrency: 'USD',
  faq: {
    type3: {
      categoryId: 1,
      questionId: 1,
    },
  },
  footer: {
    logo: 'SvgoLogoFooterDotaboom',
    copyright: 'DotaBoom.gg',
    hidePaymentMethods: true,
    hideSocials: true,
  },
  headerLogo: 'SvgoLogoHeaderDotaboom',
  legalData: {
    beneficiary: 'Big Corgi Development OÜ',
    registrationNumber: 16252850,
    address: 'Harju maakond, Tallinn, Kesklinna linnaosa, Masina tn 22, 10113',
  },
  isTechiesDisabled: true,
  hideSecretShop: true,
  hideFreeCasesBanner: true,
  hideHeaderMenuItems: [
    EHeaderMenuItems.BONUS_PAGE,
    EHeaderMenuItems.FREE_CASES,
    EHeaderMenuItems.SECRETSHOP,
    EHeaderMenuItems.UPGRADES,
    EHeaderMenuItems.CONTRACT,
    EHeaderMenuItems.TECHIES,
  ],
  hideProjectSwitcher: true,
  supportKey: 'wmpPbAPLQ9',
  seo: {
    favicon: '/img/favicon/dota-global.ico',
    title: 'DotaBoom.gg - Dota 2 Cases',
    description:
      'DotaBoom.gg is the largest Dota 2 cases site. Buy cases, get skins and cosmetics! Steam deal in 1 minute.',
  },
  statistics: {
    hideElements: [EStatisticItemVariants.ARCANES],
  },
  techWork: {
    logo: 'SvgoTechWorkLogoDotaGlobal',
    logoClass: 'logo--dota-global',
  },
  redirects: {
    newCases: ROUTING.MAINPAGE.MAINPAGE,
  },
  userAgreementVariant: 'dota-global',
};
