import ApiHttp from '~/api/global/http/ApiHttp';

export default class InventoryHttp extends ApiHttp {
  private readonly inventoryApi: string;
  private readonly sellDropApi: string;

  constructor() {
    super();
    this.inventoryApi = '/v1/inventory';
    this.sellDropApi = '/v1/inventory/sell';
  }

  public sellItem = () => {};

  public sellAllItems = async () => {
    await this.$authApi.post(this.inventoryApi + '/sell/all');
  };

  public sellDrop(id: string) {
    return this.$authApi.post(this.sellDropApi, {
      body: {
        id,
      },
    });
  }

  public tradeItem = () => {};

  public takeItem = () => {};
}
