/**
 * Преобразует ключ вида snake_case в ключ вида camelCase
 * @param str - Ключ для преобразования вида snake_case
 * @returns - Ключ вида camelCase
 */
const snake2Camel = (str: string): string => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return str.replace(/_([a-z])/g, (_, letter) => {
    return letter.toUpperCase();
  });
};

/**
 * Преобразует ключ вида camelCase в ключ вида snake_case
 * @param str - Ключ для преобразования вида camelCase
 * @returns - Ключ вида snake_case
 */
const camel2Snake = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

/**
 * Возвращает новую строку, у которой первый символ в верхнем регистре
 * @param str {T extends string} - Строка для преобразования
 * @returns {Capitalize<T>} - Строка с первым м символом в верхнем регистре
 */
const capitalize = <T extends string>(str: T): Capitalize<T> => {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>;
};

/**
 * Возвращает незаконченную строку подходящей длины
 * @param {String} str - Строка для преобразования
 * @param {Number} length - Кол-во символов до ...
 * @return {String} - Строка с ... на конце
 */
const shortenText = (str: string, length: number): string => {
  return str.length >= length ? str.slice(0, length) + '...' : str;
};

/**
 * Удалить слэш с конца строка
 * @param {String} string - Строка для преобразования
 * @return {String} - Строка без последнего символа
 */
export const sliceLastSlash = (string: string): string => {
  if (!string) return '';

  if (string[string.length - 1] === '/') {
    return string.slice(0, -1);
  }
  return string;
};

export default {
  Text: {
    camel2Snake,
    capitalize,
    shortenText,
    sliceLastSlash,
    snake2Camel,
  },
};
