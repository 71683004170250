import { volumesList } from './volumes.data';
import AudioProcessorService from '~/repository/extensions/audio/AudioProcessorService';

export const useVolumeStore = defineStore('global/volume', () => {
  // import
  const { $api } = useNuxtApp();

  // const audioElement = ref<HTMLAudioElement>();
  // const audioList = ref<Record<string, HTMLAudioElement>>({});
  const isMuteCookie: Ref<boolean | undefined> = useCookie('Volumes', {
    default: () => false,
  });

  // Громкость
  let VOLUME = 0.3;
  const audioContext = ref<AudioContext | undefined>();
  const audioList = ref<Record<string, AudioProcessorService>>({});

  // Функция изолирована в сторе, нужна для работы в режиме SSR
  // Позволяет создать контекст в момент, когда объект AudioContext уже доступен в WebApi
  const createAudioContext = () => {
    // Природа варна в консоли
    // Если AudioContext создан до того, как документ получил пользовательский жест, он будет создан в
    // "приостановленном" состоянии, и вам нужно будет вызвать resume() после получения пользовательского жеста.
    // Фикс звука на IOS + Chrome Web Audio Api
    // https://developer.chrome.com/blog/autoplay/
    // https://www.mattmontag.com/web/unlock-web-audio-in-safari-for-ios-and-macos#:~:text=It%20must%20be%20resumed%20(or,ringer%20is%20set%20to%20vibrate
    audioContext.value = new AudioContext();
  };

  const deleteLoadedAudio = (volumeKey: string) => {
    delete audioList.value[volumeKey];
  };

  const setAudioVolume = (volume: number = 0.3) => {
    VOLUME = volume;
  };

  // Подгрузить аудио дорожку предварительно
  const loadVolume = async (volumeKey: string) => {
    if (audioList.value[volumeKey] || !(volumeKey in volumesList)) return;

    const volume = volumesList[volumeKey as keyof typeof volumesList];
    const volumeParsed = await volume();
    const data = await $api.audio.getAudioBuffer(volumeParsed);

    if (!audioContext.value) createAudioContext();
    if (!isMuteCookie.value) isMuteCookie.value = false;

    const audio = new AudioProcessorService(audioContext.value!, VOLUME, isMuteCookie.value);
    await audio.createAudio(data);

    audioList.value[volumeKey] = audio;
  };

  // Воспроизводим звук из объекта заготовленных заранее звуков
  const playVolume = (volumeKey: string) => {
    if (volumeKey in audioList.value) {
      audioList.value[volumeKey].play();
    }
  };

  // Приглушить звук
  // Записывает в куки и приглушает на всех аудио дорожках
  const toggleMute = () => {
    isMuteCookie.value = !isMuteCookie.value;
    Object.keys(audioList.value).forEach((key) => {
      audioList.value[key].toggleMute(isMuteCookie.value || false);
    });
  };

  return {
    deleteLoadedAudio,
    isMuteCookie,
    loadVolume,
    playVolume,
    setAudioVolume,
    toggleMute,
  };
});
