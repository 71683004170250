import type { IUserInfo } from './authorization.types';
import type { Dto, Type } from '~/repository/modules/auth';
import { useUserStore } from '~/store/user/user.store';
import type { IError } from '~/repository/extensions/error/error.types';
import { useAlertStore } from '~/store/alert/alert.store';
import OAuthService from '~/repository/extensions/oauth/OAuth.service';
import type { TOAuthKey } from '~/features/general-auth/components/ButtonsGroup/ButtonsGroup.types';

export const TASTY_TOKEN = 'tastyToken';
export const TASTY_OLD_TOKEN = 'remember_token';

export const useAuthStore = defineStore('global/auth', () => {
  // import
  const { $api } = useNuxtApp();
  const userStore = useUserStore();
  const alertStore = useAlertStore();

  // consts
  const oauthService = new OAuthService();

  const accessToken = useCookie(TASTY_TOKEN, {
    secure: true,
  });
  const oldAccessToken = useCookie(TASTY_OLD_TOKEN);

  const userList = ref<IUserInfo[]>([]);
  const registrationInfo = ref<Type.TRegistrationResponseTransformData | object>({});
  const isPending = ref<boolean>(false);
  const error = ref<string>('');

  const isAuth = computed(() => !!accessToken.value);

  // methods

  const authByExternalToken = async () => {
    try {
      if (accessToken.value) return;

      const data: Dto.LoginResponseDto = await $fetch('/nitro-api/login', { method: 'POST' });
      if (!data) return;

      accessToken.value = data.accessToken;
    } catch {}
  };

  const oauth = async (type: TOAuthKey) => {
    try {
      const { fullPath: path } = useRoute();
      if (!path) return;
      const fullPath = useLinks().domen + path;

      oauthService.setLocation(fullPath);

      await $api.auth.oauth(type);
    } catch (e) {
      handlerError(e as IError);
    }
  };

  const logout = async () => {
    try {
      await $api.auth.revokeToken();
      await revokeTokens();

      reloadNuxtApp();
    } catch (e) {
      handlerError(e as IError);
    }
  };

  /**
   * Авторизация
   * @param {Dto.LoginRequestDto} formData - данный для логина
   * @returns {void}
   */
  const login = async (formData: Dto.LoginRequestDto): Promise<void> => {
    await GlobalUtils.Api.handleRequest(
      async () => {
        const data: Dto.LoginResponseDto = await $api.auth.login(formData);
        accessToken.value = data.accessToken;
        await userStore.getMe();
        alertStore.show({
          message: `Добро пожаловать ${userStore?.user?.nickname}`,
          title: 'Вход выполнен успешно',
          type: 'success',
        });
      },
      handlerError,
      isPending,
    );
  };

  const quickRegistration = async (): Promise<void | boolean | IError> => {
    return await GlobalUtils.Api.handleRequest(
      async () => {
        const data = await $api.auth.quickRegistration();
        if (data) {
          registrationInfo.value = data;
          alertStore.show({
            message: `Добро пожаловать ${userStore?.user?.nickname}`,
            title: 'Регистрация прошла успешно',
            type: 'success',
          });
          return true;
        }
      },
      handlerError,
      isPending,
    );
  };

  const clearError = () => (error.value = '');

  const handlerError = async (e: IError) => {
    if (e.msg) {
      alertStore.showError({
        title: e.msg,
      });
    }

    if (e.key === 'authentication' || e.key === 'unpredicted') {
      await revokeTokens();
    }
  };

  const revokeTokens = async () => {
    try {
      await $fetch('/nitro-api/logout');
    } finally {
      accessToken.value = null;
      oldAccessToken.value = null;
    }
  };

  return {
    accessToken,
    authByExternalToken,
    clearError,
    error,
    isAuth,
    isPending,
    login,
    logout,
    oauth,
    oldAccessToken,
    quickRegistration,
    registrationInfo,
    userList,
  };
});
